export const LANGUAGES = [
  {label: 'ENGLISH', code: 'en'},
  {label: 'SPANISH', code: 'es'},
];

export const Translations = {
  en: {
    translation: {
      "verification_line_1":"Let's verify your",
      "verification_line_2":"military connection.",
      "verification_line_3":"As the first & only verified community for Military Spouses, we'll need a brief 5-10 minutes of your time to validate your military connection. Once verified, you will not need to complete this step again.",
      "verificationFailed_line_2":"is a community exclusive to military spouses.",
      "verificationFailed_line_3":"As we are unable to verify your status as military spouse, we will be unable to allow you to create an account or log-in at this time.",
      "registration_line_1":"Welcome to Instant Teams Community",
      "registration_line_3":"We are so glad you've found us and we can't wait to meet you! Please complete your final community registration below.",
      "login_line_1":"Welcome to",
      "login_line_3":"From virtual coffee chats to local meetups across the globe, our community app connects you with fellow military spouses, experiences, skill development, and job opportunities.",
      "profile_line_1":"Update your profile",
      "profile_line_3":"Change your name and password",
      "email_line_1":"Reset Password",
      "email_line_3":"Enter your email address below to receive a password reset link.",
      "verificationFailed_label":"Believe there was a mistake?",
      "Choose an option":"Choose an option",
      "I'm dual military":"I'm dual military",
      "I'm a divorced spouse":"I'm a divorced spouse",
      "I'm a veteran and spouse":"I'm a veteran and spouse",
      "Back to Instant Teams Community":"Back to Instant Teams Community",
      "Registration":"Registration",
      "Complete Registration":"Complete Registration",
      "How did you hear about us?":"How did you hear about us?",
      "Facebook":"Facebook",
      "News Article or podcast":"News Article or podcast",
      "Google search":"Google search",
      "Referred by":"Referred by",
      "Flyer":"Flyer",
      "Password":"Password",
      "Confirm Password":"Confirm Password",
      "Login":"Login",
      "Email":"Email",
      "Instant Teams Community":"Instant Teams Community",
      "Update Password":"Update Password",
      "Change Password": "Change Password",
      "Reset Password": "Reset Password",
      "Forgot Password":"Forgot Password?",
      "Not registered yet":"Not registered yet?",
      "Update Name": "Update Name",
      "Save Name": "Save Name",
      "Cancel": "Cancel",
      "Return to Login": "Return to Login",
      "You must log out of the app then log back in to update your name in 12M+":"You must log out of the app then log back in to update your name in 12M+",
      "milSpouseAgree": "Instant Teams Community is a community exclusive to military spouses. I attest that I am a military spouse.",
      "Multifactor Confirmation": "Multifactor Confirmation",
    }
  },
  es: {
    translation: {
      "verification_line_1":"Verifiquemos tu",
      "verification_line_2":"conexión militar.",
      "verification_line_3":"Como la primera y única comunidad verificada para cónyuges militares, necesitaremos de 5 a 10 minutos de su tiempo para validar su conexión militar. Una vez verificado, no necesitará completar este paso nuevamente.",
      "verificationFailed_line_2":"es una comunidad exclusiva para cónyuges de militares.",
      "verificationFailed_line_3":"Como no hemos podido verificar su estado como cónyuge militar, no podremos permitirle crear una cuenta o iniciar sesión en este momento.",
      "registration_line_1":"Bienvenido a Instant Teams Community",
      "registration_line_3":"¡ Estamos muy contentos de que nos hayas encontrado y no podemos esperar a conocerte! Por favor complete su registro comunitario final a continuación",
      "login_line_1":"Bienvenido a",
      "login_line_3":"Los cónyuges de militares son el centro y la fuerza fundamental de sus familias y comunidades locales, y esta comunidad da la bienvenida a todos los cónyuges verificados en servicio activo, veteranos, y jubilados.",
      "profile_line_1":"Actualize su perfil",
      "profile_line_3":"Cambie su nombre y contraseña",
      "email_line_1":"Restablezca su contraseña",
      "email_line_3":"Ingrese su dirección de correo electrónico a continuación para recibir un enlace de restablecimiento de contraseña.",
      "verificationFailed_label":"Creo que hubo un error",
      "Choose an option":"Elige una opción",
      "I'm dual military":"Ambos somos militares",
      "I'm a divorced spouse":"Soy esposa(o) divorciada(o)",
      "I'm a veteran and spouse":"Soy veterana(o) y esposa(o)",
      "Back to Instant Teams Community":"Regresar a Instant Teams Community",
      "Registration":"Registro",
      "Complete Registration":"Complete su registro",
      "How did you hear about us?":"¿Cómo te enteraste de Instant Teams Community?",
      "Facebook":"Facebook",
      "News Article or podcast":"Artículo de noticias or podcast",
      "Google search":"Google",
      "Referred by":"Referida(o) por",
      "Flyer":"Volante publicitario",
      "Password":"Contraseña",
      "Confirm Password":"Confirme su contraseña",
      "Login":"Acceso",
      "Email":"Email",
      "Instant Teams Community":"Instant Teams Community",
      "Update Password":"Actualizar su contraseña",
      "Change Password": "Cambie su contraseña",
      "Reset Password": "Restablezca su contraseña",
      "Forgot Password":"¿Has olvidado tu contraseña",
      "Update Name": "Actualizar su nombre",
      "Not registered yet":"¿Todavía no estas registrado?",
      "Save Name":  "Guardar nombre",
      "Cancel": "Cancelar",
      "Return to Login": "Volver a iniciar sesión",
      "You must log out of the app then log back in to update your name in 12M+":"Debe cerrar sesion en la aplicacion y volver a iniciar sesion para actualizar su nombre en 12M+",
      "milSpouseAgree": "Instant Teams Community es una comunidad exclusiva para cónyuges militares. Confirmo que soy un(a) conyuge militar.",
      "Multifactor Confirmation": "Confirmación de multifactor",
    }
  }
};